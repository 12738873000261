var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-form", { ref: "form", attrs: { "label-width": "70px" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "wrap",
        attrs: { id: "wrap" },
      },
      [
        _c("topOperatingButton", {
          attrs: {
            isSubmitAddBtn: false,
            isAuditBillBtn: false,
            id: "topOperatingButton",
          },
          on: { submitForm: _vm.submitForm, getQuit: _vm.getQuit },
        }),
        _c(
          "cardTitleCom",
          { attrs: { cardTitle: "查询", id: "search-card" } },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "navSearch" },
                [
                  _c(
                    "div",
                    { staticClass: "marB10" },
                    [
                      _c("span", { staticClass: "fS14MR10" }, [
                        _vm._v("单据类型"),
                      ]),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "checkOrder",
                          attrs: { disabled: true },
                          model: {
                            value: _vm.isProduce,
                            callback: function ($$v) {
                              _vm.isProduce = $$v
                            },
                            expression: "isProduce",
                          },
                        },
                        [_vm._v("生产计划单")]
                      ),
                    ],
                    1
                  ),
                  _c("seniorSearch", {
                    class: { marB10: !_vm.showHigh },
                    attrs: {
                      filterTimeStr: "thisMonth",
                      getList: _vm.getList,
                      isInvokeList: false,
                      refreshSearch: _vm.refreshQueryParams,
                    },
                    on: { isShowHighCom: _vm.getIsShowHigh },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showHigh,
                          expression: "showHigh",
                        },
                      ],
                      staticClass: "x-w marT10",
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生产组", prop: "produceGroupIds" } },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listProduceGroup",
                                option: {
                                  option: {
                                    remoteBody: {
                                      produceDeptId:
                                        _vm.queryParams.produceDeptId,
                                    },
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.produceGroupIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "produceGroupIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.produceGroupIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "生产班次", prop: "produceOrderIds" },
                        },
                        [
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listProduceOrder",
                                option: {
                                  option: {
                                    remoteBody: {
                                      produceDeptId:
                                        _vm.queryParams.produceDeptId,
                                    },
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.produceOrderIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryParams,
                                  "produceOrderIds",
                                  $$v
                                )
                              },
                              expression: "queryParams.produceOrderIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c(
          "cardTitleCom",
          { attrs: { cardTitle: "选择源单" } },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "tableContent" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                      },
                      on: {
                        "row-click": _vm.handleRowClick,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": "",
                          label: "单据编号",
                          align: "center",
                          prop: "billNo",
                          width: "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据日期",
                          align: "center",
                          prop: "billDate",
                          width: "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产工厂",
                          align: "center",
                          prop: "produceDeptName",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产组",
                          align: "center",
                          prop: "produceGroupName",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产班次",
                          align: "center",
                          prop: "produceOrderName",
                          width: "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据状态",
                          align: "center",
                          prop: "billStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "领料状态",
                          align: "center",
                          prop: "leadMaterialStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "完工状态",
                          align: "center",
                          prop: "finishWorkStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "质检状态",
                          align: "center",
                          prop: "qualityCheckStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库状态",
                          align: "center",
                          prop: "inStoreStatusName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产日期",
                          align: "center",
                          prop: "produceDate",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "备注",
                          align: "center",
                          prop: "billRemark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      idsCom: _vm.ids,
                      totalCom: _vm.total,
                    },
                    on: { selectAllCom: _vm.selectAll },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }